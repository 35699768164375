import { useTranslation } from 'react-i18next';
import './find-loads-search-timeout.component.scss';

export const LoadsSearchTimeout = () => {
  const { t } = useTranslation();

  return (
    <div className="find-loads-timeout">
      <img src="/assets/images/traffic-cone.svg" aria-hidden={true} data-testid="traffic-cone-image"/>
      <h2>{t('WE_ARE_SORRY_RESULTS_TOOK_TOO_LONG_TO_LOAD')}</h2>
      <p>{t('PLEASE_REFRESH_YOUR_BROWSER_OR_TRY_SEARCHING_AGAIN')}</p>
    </div>
  )
};
