import React from 'react';
import { ReactElement } from 'react';

import { FormError } from 'shared/components/formatters/form-error.formatter';
import { ComponentConnectorFactory } from 'store/component-connector';
import { select_findLoads } from 'shared/find-loads/redux/find-loads.selectors';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { LoadsSearchTimeout } from '../../../pages/find-loads-ver2/results/find-loads-search-timeout.component';

interface ConnectStateProps {
  loads: AvailableLoadSummary[];
  errorResponse: APIErrorResponse;
}

interface OwnProps {
  hideNotFoundError?: boolean;
}

type Props = ConnectStateProps & OwnProps;
export const SearchErrorComponent: React.FunctionComponent<Props> = ({ loads, errorResponse, hideNotFoundError }: Props) =>
  <React.Fragment>
    {getErrorMessage(errorResponse)}
    <FormError
      className="e2e-no-results-error"
      display={!hideNotFoundError && Boolean(Array.isArray(loads) && !loads.length)}
      resource="THERE_ARE_NO_LOADS_MATCHING_CRITERIA"
    />
  </React.Fragment>;

export const SearchError = ComponentConnectorFactory<{}, ConnectStateProps>()
  .combineStateSelectors(
    select_findLoads.results.loads,
    select_findLoads.select(state => ({ errorResponse: state.error }))
  )
  .connect(SearchErrorComponent);

const getErrorMessage = (errorResponse: APIErrorResponse): ReactElement<any> => {
  if (!errorResponse) {
    return;
  }
  switch (errorResponse.status) {
    case 409:
      return <FormError resource="INVALID_SEARCH_CRITERIA" />;
    case 408:
      return <LoadsSearchTimeout />;
    case 500:
    default:
      return <FormError resource="AN_UNKNOWN_ERROR_OCCURRED_TRYING_TO_MAKE_THE_REQUE" />;
  }
};
